<mat-form-field class="w-full mt-3">
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <ng-container cdkScrollable>
    <input
      matInput
      [value]="selecionado"
      [matAutocomplete]="autoComplete6"
      (keyup)="filterByQuery($event.target.value)"
    />

    <mat-autocomplete
      matNativeControl
      name="autoComplete"
      #autoComplete6="matAutocomplete"
      (optionSelected)="set($event.option.value)"
    >
      <ng-container *ngIf="sindicatos$ | async as sindicatos">
        <mat-option [value]="m" *ngFor="let m of sindicatos">
          {{ m?.nome }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </ng-container>
</mat-form-field>
