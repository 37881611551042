import { Observable } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { Injectable } from '@angular/core';
import { Client, Message } from '@stomp/stompjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private lastId: { [key: string]: string } = {};
  private stompClients: { [key: string]: Client } = {};

  constructor() {}

  connect(url: string, idSocket: string, stomp: string): Observable<Message> {
    if (this.stompClients[stomp]) {
      this.stompClients[stomp].forceDisconnect();
      this.stompClients[stomp].deactivate();

      delete this.stompClients[stomp];
    }

    const socket = new SockJS(url + '/' + idSocket);
    this.stompClients[stomp] = new Client();
    this.stompClients[stomp].webSocketFactory = (): any => socket;

    this.lastId[stomp] = idSocket;

    return new Observable<Message>((observer) => {
      const headers: any = {}; // Adicione cabeçalhos se necessário

      this.stompClients[stomp].onConnect = (frame): void => {
        console.warn('Connect STOMP:', idSocket, frame);

        try {
          this.stompClients[stomp].subscribe(
            '/socketProcessor/' + idSocket,
            (message) => {
              observer.next(message);
            },
            headers
          );
        } catch (e) {
          console.error('Erro tentativa conexao id: ' + idSocket);
        }
      };

      this.stompClients[stomp].onStompError = (frame): void => {
        // Aqui você pode lidar com erros de STOMP
        console.error('Erro no STOMP:');
        console.error(frame);
      };

      this.stompClients[stomp].onWebSocketError = (frame): void => {
        // Aqui você pode lidar com erros de STOMP
        console.error('Erro no WEBSCOKET:');
        console.error(frame);
      };

      this.stompClients[stomp].activate();
    });
  }

  disconnect(stomp: string): any {
    if (this.stompClients[stomp] && this.stompClients[stomp].connected) {
      this.stompClients[stomp].forceDisconnect();

      return this.stompClients[stomp].deactivate();
    }
  }
}
