<section class="w-full flex flex-row justify-end -mr-3 -mt-3 -mb-10">
  <button tabindex="-1" mat-icon-button matDialogClose color="warn">
    <mat-icon>close</mat-icon>
  </button>
</section>

<div #exportQr>
  <qrcode
    #canvas
    [width]="width"
    [qrdata]="text"
    [errorCorrectionLevel]="'M'"
  ></qrcode>

  <section
    class="w-full flex justify-center items-center -mt-6 pb-4 font-bold text-xl"
  >
    <span>{{ descricao }}</span>
  </section>
</div>
