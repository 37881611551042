import html2canvas from 'html2canvas';
import { QrcodeService } from './qrcode.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
})
export class QrcodeComponent implements OnInit {
  @ViewChild('exportQr', { static: true }) exportQr: ElementRef;

  text = '';
  width = 50;
  descricao = '';

  constructor(private _service: QrcodeService) {}

  ngOnInit(): void {
    this.width = window.innerHeight * 0.9;

    this._service.text$.subscribe((text) => {
      this.text = text;

      this.createDescricao();
    });

    setTimeout(() => {
      this.salvarComoPNG();
    }, 500);
  }

  createDescricao(): void {
    this.descricao = this.text;

    this.descricao = this.descricao.replace('http://', '');
    this.descricao = this.descricao.replace('https://', '');
    this.descricao = this.descricao.replace('www.', '');
    this.descricao = this.descricao.replace('.com', '');
    this.descricao = this.descricao.replace('.br', '');
  }

  salvarComoPNG(): void {
    html2canvas(this.exportQr.nativeElement).then((canvas) => {
      const link = document.createElement('a');

      const titulo = this.descricao.slice(0, 12);

      link.download = titulo + '.png';

      const imageData = canvas.toDataURL('image/png');

      link.href = imageData;

      link.click();
    });
  }
}
