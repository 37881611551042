import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { QrcodeComponent } from './qrcode.component';

@Injectable({
  providedIn: 'root',
})
export class QrcodeService {
  private _text: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private _matDialog: MatDialog) {}

  get text$(): Observable<string> {
    return this._text.asObservable();
  }

  startComponent(inputValue: any): void {
    this._text.next(inputValue);

    const modal = this._matDialog.open(QrcodeComponent, {
      width: 'auto',
      height: 'auto',
    });
  }
}
