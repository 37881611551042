import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges, QueryList } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSelectComponent),
      multi: true
    }
  ]
})
export class AutocompleteSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() title;
  @Input() options;
  @Input() required;
  @Input() disabled: boolean;
  @Input() loading;
  @Input() length: number = 50;
  filteredOptions = [];
  @Output() callParent = new EventEmitter<any>();
  @Output() optionSelected = new EventEmitter<any>();
  @Output() blurEmitter = new EventEmitter<any>();
  @Output() focusEmitter = new EventEmitter<any>();
  txtLabel = '';
  value = 0;
  error: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = cloneDeep(this.options)?.splice(0, this.length || Number.MAX_SAFE_INTEGER);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.txtLabel) {
      const index = this.options.findIndex(o => o.label === this.txtLabel);
      if (index === -1)
        this.txtLabel = '';
    }
    this.filter(this.txtLabel);
  }

  filter(text): void {
    this.filteredOptions = this.options?.filter(o => {
      const nomeNormalizado = o.label?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const descNormalizado = text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return nomeNormalizado?.toLowerCase().includes(descNormalizado?.toLowerCase())
    }).splice(0, this.length || Number.MAX_SAFE_INTEGER);
    if (!text.length) {
      this.value = null;
      this.onChange(null);
      this.optionSelected.emit(null);
    }
  }

  setOption(option): void {
    if (!option) {
      this.filter('');
      this.txtLabel = '';
      this.value = null;
      this.onChange(null);
      this.optionSelected.emit(null);
    } else {
      const op = this.options?.find(o => typeof o.value !== 'object' ? o.value === option : this.objectsAreEqual(o.value, option));
      if (op) {
        this.filter(op.label);
        this.txtLabel = op.label;
        this.value = op.value;
        this.onChange(op.value);
        this.optionSelected.emit(op);
        this.validacao(op.label);
      }
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: number): void {
    this.value = value;
    this.setOption(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  blur(e) {
    this.blurEmitter.emit(e);
    this.validacao(e);
  }

  focus(e) {
    this.focusEmitter.emit(e);
  }

  validacao(e) {
    if (!e.length) {
      const index = this.filteredOptions.findIndex(fo => fo.value === this.value);
      if (index > -1)
        this.filteredOptions.splice(index, 1);
      return this.error = false;
    }
    if (e.length && !this.value) {
      this.error = true;
      this.value = null;
      this.onChange(null);
      this.optionSelected.emit(null);
      return;
    }
    if (this.value) {
      const option = this.options.find(o => o.value === this.value);
      if (option) {
        if (option.label.replace(/[\r\n]/g, '') === e.replace(/[\r\n]/g, '')) {
          const index = this.filteredOptions.findIndex(fo => fo.value === this.value);
          if (index > -1)
            this.filteredOptions.splice(index, 1);
          this.error = false;
        } else {
          this.error = true;
          this.value = null;
          this.onChange(null);
          this.optionSelected.emit(null);
        }
      } else {
        this.error = true;
        this.value = null;
        this.onChange(null);
        this.optionSelected.emit(null);
      }
      return;
    }
  }

  objectsAreEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (typeof obj1 !== typeof obj2) {
      return false;
    }

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== null && typeof obj1[key] === 'object') {
        return this.objectsAreEqual(obj1[key],  obj2[key]);
      } else {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  }

  displayFn(value: any) {
    if (!value) return '';
    const options: QueryList<any> = this.options;
    const option: MatOption = options?.find(o => o?.value === value)
    // @ts-ignore
    return typeof value === 'string' ? value : option?._element?.nativeElement?.innerText;
  }

}
