import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { QrcodeService } from './shared/components/qrcode/qrcode.service';
import { SelectSindicatoService } from './shared/components/select-sindicato/select-sindicato.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _auth: AuthService,
    private _sindicatos: SelectSindicatoService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const resolvers = [of(true)];

    const token = this._auth?.accessToken;

    if (token) {
      resolvers.push(this._sindicatos.getSindicatos());
    }

    return forkJoin(resolvers);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CreateQrCodeResolver implements Resolve<any> {
  constructor(private _service: QrcodeService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable((observer) => {
      Swal.fire({
        title: 'Gerar QR-CODE',
        text: 'Insira logo abaixo o que você queira converter em QR CODE :',
        input: 'text',
        showLoaderOnConfirm: true,
        confirmButtonText: 'Converter',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: async (text) => {
          this._service.startComponent(text);
        },
      });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class GetAllVinculoMeioRuralResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _appService: AppService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this._appService.getAllVinculoMeioRural();
  }
}