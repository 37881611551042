<mat-form-field class="w-full" [ngClass]="{'mat-form-field-invalid': error}">
    <mat-label *ngIf="title">{{ title }}</mat-label>
    <input [(ngModel)]="txtLabel" [ngModelOptions]="{standalone: true}" matInput [matAutocomplete]="autoComplete"
        (keyup)="filter($event.target.value)" (blur)="blur($event.target.value)" (focus)="focus($event.target.value)" [required]="required" [disabled]="disabled">
    <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="24"
        *ngIf="loading"></mat-progress-spinner>
    <mat-autocomplete [displayWith]="displayFn" matNativeControl #autoComplete="matAutocomplete" name="autoComplete" (optionSelected)="setOption($event.option.value)">
        <mat-option *ngFor="let o of filteredOptions" [value]="o.value">
            {{ o.label }}
        </mat-option>
        <mat-option *ngIf="!filteredOptions?.length" disabled>
            <span>Sem resultados</span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<mat-error class="text-sm -mt-4 mb-1" *ngIf="error">
    Opção inválida
</mat-error>