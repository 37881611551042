import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, catchError, first, of, switchMap, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class TalentosDoCampoService {
    private _ufs: BehaviorSubject<any> = new BehaviorSubject([]);
    private _pessoaMunicipio: BehaviorSubject<any> = new BehaviorSubject([]);
    private _areaAtuacao: BehaviorSubject<any> = new BehaviorSubject([]);
    private _conteudoCaracteristicasDoProfissional: BehaviorSubject<any> = new BehaviorSubject([]);
    private _cargos: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService
    ) { }

    get ufs$(): Observable<any> {
        return this._ufs.asObservable();
    }

    get pessoaMunicipio$(): Observable<any> {
        return this._pessoaMunicipio.asObservable();
    }

    get areaAtuacao$(): Observable<any> {
        return this._areaAtuacao.asObservable();
    }

    get conteudoCaracteristicasDoProfissional$(): Observable<any> {
        return this._conteudoCaracteristicasDoProfissional.asObservable();
    }

    get cargos$(): Observable<any> {
        return this._cargos.asObservable();
    }

    auth() {
        return this._httpClient.post(`${environment.talentos}/auth/login`, { android: null, email: "infosindical@sistemafaeg.org.br", ios: null, senha: "123456" })
            .pipe(
                switchMap((response: any) => {
                    localStorage.setItem("talentosToken", response.token);
                    localStorage.setItem("talentosUsuario", response._id);
                    return of(response.token);
                })
            );
    }

    getAreaAtuacao(): Observable<any> {
        return this._httpClient.get(`${environment.talentos}/areas-atuacao`).pipe(
            catchError((error) => {
                return of(this._areaAtuacao.next([]));
            }),
            tap((response: any) => {
                this._areaAtuacao.next(
                    response.sort(function (a: any, b: any) {
                        var textA = a.descricao.toUpperCase();
                        var textB = b.descricao.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    })
                );
            })
        );
    }

    getCaracteristicasDoProfissional(): Observable<any> {
        return this._httpClient.get(`${environment.talentos}/conteudo-caracteristicas-do-profissional/6335e46e8cef9dfac47a8e94`).pipe(
            catchError((error) => {
                return of(this._conteudoCaracteristicasDoProfissional.next([]));
            }),
            tap((response: any) => {
                this._conteudoCaracteristicasDoProfissional.next(
                    response
                );
            })
        );
    }

    getCargos(): Observable<any> {
        return this._httpClient.get(`${environment.talentos}/cargo`).pipe(
            catchError((error) => {
                return of(this._cargos.next([]));
            }),
            tap((response: any) => {
                this._cargos.next(
                    response
                );
            })
        );
    }

    verficarAlteracao(formulario: any, banco: any) {
        let valid = 0;

        for (const dado in formulario) {
            if (JSON.stringify(formulario[dado]) !== JSON.stringify(banco[dado])) {
                valid++;
            }
        }

        if (valid > 0) {
            return true;
        } else {
            return false;
        }
    }

    CpfouCnpj(valor: any) {
        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, "");

        if (valor.length === 11) {
            return "CPF";
        } else if (valor.length === 14) {
            return "CNPJ";
        } else {
            return false;
        }
    }

    ValidarCpf(valor: any) {
        if (
            valor == "00000000000" ||
            valor == "11111111111" ||
            valor == "22222222222" ||
            valor == "33333333333" ||
            valor == "44444444444" ||
            valor == "55555555555" ||
            valor == "66666666666" ||
            valor == "77777777777" ||
            valor == "88888888888" ||
            valor == "99999999999"
        ) {
            return false;
        }

        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, "");

        let digitos = valor.substr(0, 9);

        let novo_cpf = this.CalcularDigitos(digitos);
        novo_cpf = this.CalcularDigitos(novo_cpf, 11);

        if (novo_cpf === valor) {
            return true;
        } else {
            return false;
        }
    }

    CalcularDigitos(digitos: any, posicoes = 10, soma_digitos = 0) {
        digitos = digitos.toString();

        for (let i = 0; i < digitos.length; i++) {
            soma_digitos = soma_digitos + digitos[i] * posicoes;
            posicoes--;
            if (posicoes < 2) {
                posicoes = 9;
            }
        }

        soma_digitos = soma_digitos % 11;

        if (soma_digitos < 2) {
            soma_digitos = 0;
        } else {
            soma_digitos = 11 - soma_digitos;
        }

        let cpf = digitos + soma_digitos;

        return cpf;
    }

    ValidarCnpj(valor: any) {
        valor = valor.toString();

        valor = valor.replace(/[^0-9]/g, "");

        let cnpj_original = valor;

        let primeiros_numeros_cnpj = valor.substr(0, 12);

        let primeiro_calculo = this.CalcularDigitos(primeiros_numeros_cnpj, 5);

        let segundo_calculo = this.CalcularDigitos(primeiro_calculo, 6);

        let cnpj = segundo_calculo;

        if (cnpj === cnpj_original) {
            return true;
        }
        return false;
    }

    valida_cpf_cnpj(valor: any) {
        let valida = this.CpfouCnpj(valor);
        valor = valor.toString();
        valor = valor.replace(/[^0-9]/g, "");

        if (valida === "CPF") {
            return this.ValidarCpf(valor);
        } else if (valida === "CNPJ") {
            return this.ValidarCnpj(valor);
        } else {
            return false;
        }
    }

    cpfCadastrado(cpf: any, dado?: any) {
        const resposta = this.valida_cpf_cnpj(cpf);

        if (!resposta) {
            Swal.fire({
                title: "CPF / CNPJ invalido",
                icon: "error",
                timer: 2500,
                showConfirmButton: false,
            });
        } else if (resposta) {
            if (!dado) {
                return this._httpClient
                    .get(`${environment.talentos}/usuarios/cpf/${cpf}`)
                    .pipe(first());
            } else {
                this._httpClient
                    .get(`${environment.talentos}/usuarios/cpf/${cpf}`)
                    .pipe(first())
                    .subscribe((res: any) => {
                        if (res?.length > 1 || (res[0]?._id && res[0]?._id !== dado)) {
                            Swal.fire({
                                title: "CPF / CNPJ Já cadastrado",
                                icon: "info",
                            });
                            cpf.reset();
                        } else {
                            // this.saveCpfEmail({
                            //     cpfCnpj: cpf.value,
                            //     _id: dado,
                            // });
                        }
                    });
            }
        }
    }

    // saveCpfEmail(dados: any) {
    //     this._httpClient
    //         .put(`${environment.talentos}/usuarios/${dados._id}`, dados)
    //         .pipe(
    //             tap((response: any) => {
    //                 this._usuario.next(response);
    //             })
    //         ).subscribe(
    //             (res: any) => (this.auth.currentUserValue = res)
    //         );
    // }

    get(cep: string) {
        return this._httpClient.get(`https://viacep.com.br/ws/${cep}/json/`);
    }

    /**
     * Cadastro Talento Empregador
     */

    getUFs(): Observable<any> {
        return this._httpClient.get(`${environment.talentos}/uf`).pipe(
            catchError((error) => {
                return of(this._ufs.next([]));
            }),
            tap((response: any) => {
                this._ufs.next(response);
            })
        );
    }

    getPessoaMunicipio(cpf): Observable<any> {
        return this._httpClient.get(`${environment.api}/pessoaMunicipio/buscarMunicipiosRelacionadaPessoaPorCpf/${cpf}`).pipe(
            catchError((error) => {
                return of(this._pessoaMunicipio.next([]));
            }),
            tap((response: any) => {
                this._pessoaMunicipio.next(response);
            })
        );
    }

    findByCpf(cpf) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.api}/pessoa/findByCpf/${cpf}`)
                .subscribe(
                    (res) => resolve(res),
                    (err) => reject(err)
                );
        });
    }

    salvar(data: any, novo: boolean, flagGeracaoSenhaAutomatica?: boolean) {
        const {
            pai,
            cpf,
            nome,
            email,
            senha,
            status,
            cpfCnpj,
            celular,
            telefone,
            sindicato,
            permissao,
            identificacao,
            online = false,
            confirmacaoSenha,
            usuarioNovo = novo,
            emailConfirmado = false,
        } = data;
        return this._httpClient.post(`${environment.talentos}/usuarios`, {
            pai,
            nome,
            email,
            senha,
            online,
            status,
            celular,
            permissao,
            usuarioNovo,
            emailConfirmado,
            confirmacaoSenha,
            flagGeracaoSenhaAutomatica,
            cpfCnpj: cpf ? cpf : cpfCnpj,
        })
    }

    salvarEmpregador(empregador) {
        const user = this._authService.userValue;
        return this._httpClient.post(`${environment.talentos}/empregadores`, { ...empregador, usuarioInfosindical: user.login });
    }

    salvarTalento(talento) {
        const user = this._authService.userValue;
        return this._httpClient.post(`${environment.talentos}/talentos`, { ...talento, usuarioInfosindical: user.login });
    }

    confirmacaoConta(email: string, domain: string) {
        return this._httpClient.post(`${environment.talentos}/usuarios/confirmacao-conta`, {
            email,
            domain,
        });
    }

    salvarPropriedade(propriedade) {
        return this._httpClient.post(`${environment.api}/pessoaMunicipio/salvarPropriedadeTalentos`, propriedade);
    }
}
