import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
} from 'rxjs';
import { FilterSindicatosService } from './filter-sindicatos.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-filter-sindicatos',
  templateUrl: './filter-sindicatos.component.html',
})
export class FilterSindicatosComponent implements OnInit {
  constructor(private _service: FilterSindicatosService) {
    this._service.getSindicatos().then().catch();
  }

  @Input() id: Boolean;
  @Input() label: String;
  @Output() idSelecionado = new EventEmitter<Number>();

  filtrando: any;
  sindicatos: any;
  selecionado: string = '';
  sindicatos$: Observable<any[]>;
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject(null);

  ngOnInit(): void {
    this.sindicatos$ = combineLatest([
      this._service.sindicatos$,
      this.searchQuery$,
    ]).pipe(
      distinctUntilChanged(),
      map(([sindicatos, searchQuery]) => {
        let filtred;

        if (this.id && sindicatos) {
          const select = sindicatos.find((res) => res.id == this.id);
          this.selecionado = select?.nome;
        }

        if (searchQuery && searchQuery?.length > 3) {
          searchQuery = searchQuery.trim().toLowerCase();

          filtred = sindicatos.filter(
            (sindicato) =>
              sindicato?.nome
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              sindicato?.descricaoMunicipio
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase())
          );
        }

        this.filtrando = false;

        if (!searchQuery || (searchQuery && searchQuery?.length < 3)) {
          return;
        }

        return filtred;
      })
    );
  }

  set(option: any) {
    this.selecionado = option?.nome;

    this.idSelecionado.emit(option);
  }

  filterByQuery(query: string): void {
    if (this.filtrando) {
      return;
    }

    this.filtrando = true;

    this.searchQuery$.next(query);
  }
}
