import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GraficoDinamicaService {
  private _totais: BehaviorSubject<any> = new BehaviorSubject(null);
  private _config: BehaviorSubject<any> = new BehaviorSubject(null);
  private _participantes: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  get participantes$(): Observable<any> {
    return this._participantes.asObservable();
  }

  get totais$(): Observable<any> {
    return this._totais.asObservable();
  }

  getDinamicaConfig(campanhaId: number): Observable<any> {
    return this._httpClient
      .get<any[]>(`${environment.api}/bot/getDinamicaConfig/${campanhaId}`)
      .pipe(
        tap((response: any[]) => {
          this._config.next(response);
        })
      );
  }

  getDinamicaParticipantes(campanhaId: number): Observable<any> {
    return this._httpClient
      .get<any[]>(
        `${environment.api}/bot/getDinamicaParticipantes/${campanhaId}`
      )
      .pipe(
        tap((response: any[]) => {
          this._participantes.next(response);
        })
      );
  }

  getDinamicaTotais(campanhaId: number): Observable<any> {
    return this._httpClient
      .get<any[]>(`${environment.api}/bot/getDinamicaTotais/${campanhaId}`)
      .pipe(
        catchError(() => of(true)),
        tap((response: any[]) => {
          this._totais.next(response);
        })
      );
  }
}
