import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResumoCursosService } from './resumo-cursos.service';

@Component({
  selector: 'app-resumo-cursos',
  templateUrl: './resumo-cursos.component.html',
  styleUrls: ['./resumo-cursos.component.scss'],
})
export class ResumoCursosComponent implements OnInit {
  css = {};
  itens: any = [];
  lista: any[] = [];
  selecionado: any = {};
  progressos: any[] = [];

  constructor(
    private _serivce: ResumoCursosService,
    private _matDialogRef: MatDialogRef<ResumoCursosComponent>
  ) {}

  ngOnInit(): void {
    this._serivce.lista$.subscribe((lista) => {
      this.lista = lista;
    });

    this._serivce.progressos$.subscribe((progressos) => {
      this.progressos = progressos;
    });

    this._serivce.selecionado$.subscribe((selecionado) => {
      this.selecionado = selecionado;
    });

    this.itens = this.insertCss(this.lista);
  }

  insertCss(itens: any[]): any[] {
    itens.map((item, index) => {
      const css = this.getColor(item);

      this.css[index] = css;
    });

    return itens;
  }

  getColor(item: any): string {
    let css = 'relative flex flex-col flex-auto p-6 shadow mb-3 mx-5 ';

    if (this.itens?.length === 1) {
      css += ' bg-lime-700 ';

      return css;
    }

    const check: any = this.progressos?.find(
      (fin: any) => fin?.resumo === item?.id && fin?.tipoResumo === item?.tipo
    );

    const selecionado =
      this.selecionado?.id === item?.id &&
      item?.tipo === this.selecionado?.tipo;

    if (selecionado) {
      css += ' bg-green-700 ';

      return css;
    } else if (check?.resposta?.length) {
      css += ' bg-gray-500 hover:bg-gray-400 cursor-pointer';

      return css;
    }

    css += ' bg-blue-900 hover:bg-blue-800 cursor-pointer';

    return css;
  }

  selecionar(item: any): void {
    this._matDialogRef.close(item);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
