<button
  [matMenuTriggerFor]="matMenu"
  class="flex items-center relative self-start py-3 px-6 ml-3 cursor-pointer overflow-hidden rounded-xl border border-b-0 bg-default w-max"
>
  <div *ngIf="!blockSindicatos" class="font-medium text-lg">
    {{ textoSindicato || "Sem Sindicato" }}
  </div>

  <div *ngIf="blockSindicatos" class="font-medium text-lg">
    {{ textoMunicipio || "TODOS" }}
  </div>

  <div class="flex items-center justify-center pl-2">
    <mat-icon
      class="icon-size-5"
      [svgIcon]="'heroicons_solid:chevron-down'"
    ></mat-icon>
  </div>
</button>

<!-- ================= MENU POP-UP =========================== -->

<mat-menu
  #matMenu="matMenu"
  [overlapTrigger]="false"
  class="bg-default min-w-120"
>
  <mat-form-field
    *ngIf="!blockSindicatos"
    (click)="$event.stopPropagation()"
    class="min-w-120 py-2 px-6 mt-2 -mb-6"
  >
    <mat-label>Sindicato</mat-label>

    <input
      matInput
      class="w-full"
      autocomplete="off"
      name="enganando chrome"
      [value]="textoSindicato"
      [matAutocomplete]="autoComplete"
      [placeholder]="'Selecione um sindicato'"
      (keyup)="getGrupoFiliacao($event.target.value)"
    />

    <mat-autocomplete
      matNativeControl
      #autoComplete="matAutocomplete"
      (optionSelected)="setSindicato($event.option.value)"
    >
      <mat-option value="TODOS" *ngIf="sindicatos?.length"> TODOS </mat-option>

      <ng-container *ngFor="let dado of sindicatos">
        <mat-option *ngIf="selecionado?.id !== dado?.id" [value]="dado.id">
          <span class="text-black"> {{ dado?.nome }}</span>
        </mat-option>
      </ng-container>

      <mat-option *ngIf="!sindicatos?.length" disabled>
        <span>Sem resultados</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    class="min-w-120 py-2 px-6 mt-2"
    (click)="$event.stopPropagation()"
  >
    <mat-label>Município</mat-label>

    <input
      matInput
      [value]="textoMunicipio"
      [matAutocomplete]="autoComplete2"
      (keyup)="getMunicipio($event.target.value)"
    />

    <mat-autocomplete
      matNativeControl
      #autoComplete2="matAutocomplete"
      (optionSelected)="setMunicipio($event.option.value)"
    >
      <mat-option value="TODOS"> TODOS </mat-option>

      <mat-option *ngFor="let m of filteredMunicipio" [value]="m">
        {{ m.descricao }} - {{ m.uf }}
      </mat-option>

      <mat-option *ngIf="!filteredMunicipio.length" disabled>
        <span>Sem resultados</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mat-menu>
