import { LeiteBemService } from './../../modules/servicos/leite-bem/leite-bem.service';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { TalentosDoCampoService } from 'app/modules/servicos/talentos-do-campo/talentos-do-campo.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private leiteBemService: LeiteBemService,
    private router: Router,
    private talentoDoCampoService: TalentosDoCampoService
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      this._authService
        .accessToken /* && !AuthUtils.isTokenExpired(this._authService.accessToken) */
    ) {
      let bearer = this._authService.accessToken;
      if (req.url.includes(environment.apiLeiteBem)) {
        bearer = localStorage.getItem('adminToken');
      }
      if (req.url.includes(environment.talentos)) {
        bearer = localStorage.getItem('talentosToken');
      }
      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + bearer),
        // headers: req.headers.set(
        //   'Authorization',
        //   'Bearer ' +
        //   (req.url.includes(environment.apiLeiteBem)
        //     ? localStorage.getItem('adminToken')
        //     : this._authService.accessToken)
        // ),
      });
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (req.url.includes(environment.apiLeiteBem)) {
            return this.leiteBemService.auth().pipe(
              catchError((error) => {
                this.router.navigate(['/dashboards']);

                return throwError(error);
              }),
              switchMap((token) => {
                newReq = req.clone({
                  headers: req.headers.set('Authorization', 'Bearer ' + token),
                });
                return next.handle(newReq).pipe(
                  catchError((error) => {
                    if (
                      error instanceof HttpErrorResponse &&
                      error.status === 401
                    ) {
                      this.router.navigate(['/dashboards']);
                    }
                    return throwError(error);
                  })
                );
              })
            );
          } else {
            if (req.url.includes(environment.talentos)) {
              return this.talentoDoCampoService.auth().pipe(
                catchError((error) => {
                  this.router.navigate(['/dashboards']);

                  return throwError(error);
                }),
                switchMap((token) => {
                  newReq = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Bearer ' + token
                    ),
                  });
                  return next.handle(newReq).pipe(
                    catchError((error) => {
                      if (
                        error instanceof HttpErrorResponse &&
                        error.status === 401
                      ) {
                        this.router.navigate(['/dashboards']);
                      }
                      return throwError(error);
                    })
                  );
                })
              );
            } else {
              // Sign out
              this._authService.signOut();

              // Reload the app
              location.reload();

              return throwError(error);
            }
          }
        } else {
          return throwError(error);
        }
      })
    );
  }
}
