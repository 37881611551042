import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-foto',
  templateUrl: './foto.component.html',
  styleUrls: ['./foto.component.scss'],
})
export class FotoComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _matDialogRef: MatDialogRef<FotoComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    this._matDialogRef.close();
  }
}
