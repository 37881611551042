import {
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexStroke,
  ApexTooltip,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexTitleSubtitle,
  ApexAxisChartSeries,
  ApexLegend,
} from 'ng-apexcharts';
import { AuthService } from 'app/core/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GraficoDinamicaService } from './grafico-dinamica.service';

export type ChartOptions = {
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  series: ApexAxisChartSeries;
  subtitle: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
};

@Component({
  selector: 'app-grafico-dinamica',
  templateUrl: './grafico-dinamica.component.html',
  styleUrls: ['./grafico-dinamica.component.scss'],
})
export class GraficoDinamicaComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  data = [];
  celulares = [];
  user: any = {};
  quantidade = 12;
  totais: any = {};
  config: any = {};
  corretos: any = {};
  chartOptions: Partial<ChartOptions>;

  defaultBack =
    '../../../../../../../../../../assets/images/banners/graficoDinamico.jpg';

  constructor(
    private _user: AuthService,
    private _service: GraficoDinamicaService
  ) {}

  ngOnInit(): void {
    this._user.userValue$.subscribe((user) => {
      this.user = user;
    });

    const altura = window.innerHeight;

    this._service.config$.subscribe((config) => {
      this.corretos = JSON.parse(config?.corretos);

      this.config = config;
    });

    this._service.participantes$.subscribe((participantes) => {
      this.data = participantes.map((part: any) => part.total);

      this.celulares = participantes.map((part: any) => part.celular);
    });

    this._service.totais$.subscribe((totais) => {
      if (totais?.length) {
        this.totais = totais[0];
      }
    });

    if (this.data?.length) {
      this.quantidade = this.data[0] + 2;
    }

    this.chartOptions = {
      series: [
        {
          data: this.data,
        },
      ],
      chart: {
        type: 'bar',
        height: altura * 0.9,
        toolbar: {
          show: false,
        },
        dropShadow: {
          color: 'red',
        },
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'center',
          },
        },
      },
      colors: [
        '#33b2df',
        '#546E7A',
        '#d4526e',
        '#13d8aa',
        '#A5978B',
        '#2b908f',
        '#f9a3a4',
        '#90ee7e',
        '#f48024',
        '#69d2e7',
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          fontSize: '25',
          colors: ['#ffffff'],
        },
        formatter: (val, opt): string =>
          this.ocultarNumeroCelular(opt.w.globals.labels[opt.dataPointIndex]) +
          ' --->  ' +
          val +
          ' acertos',
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 2,
        colors: ['#252525'],
      },
      xaxis: {
        categories: this.celulares,
        labels: {
          offsetY: 10,
          style: {
            fontSize: '20',
            colors: 'white',
          },
        },
      },
      yaxis: {
        max: this.quantidade,
        labels: {
          show: false,
        },
      },
      title: {
        text: this.config?.nome ?? 'Titulo do Grafico',
        align: 'left',
        floating: true,
        offsetX: 30,
        margin: 80,
        style: {
          fontSize: '50',
          color: 'white',
        },
      },
      tooltip: {
        enabled: false,
      },
    };

    const body = document.body;

    const newDiv = document.createElement('div');
    newDiv.className = 'absolute w-full h-full overflow-hidden';

    const background = this.config?.background
      ? this.config?.background
      : this.defaultBack;

    newDiv.style.backgroundImage = `url("${background}")`;
    newDiv.style.backgroundSize = 'cover';

    const maskDiv = document.createElement('div');
    maskDiv.className = 'absolute w-full h-full bg-black opacity-30';
    newDiv.appendChild(maskDiv);

    const text = document.createTextNode('');
    newDiv.appendChild(text);

    body.insertBefore(newDiv, body.firstChild);
  }

  ocultarNumeroCelular(numeroCelular: string): string {
    if (this.user?.admin || this.user?.campanhaAdmin) {
      return numeroCelular;
    }

    // Obtém os quatro primeiros caracteres do número de celular
    const prefixo = numeroCelular.substring(0, 2);

    // Obtém os quatro últimos caracteres do número de celular
    const sufixo = numeroCelular.substring(
      numeroCelular.length - 4,
      numeroCelular.length
    );

    // Concatena o prefixo, quatro caracteres ocultos e o sufixo
    const numeroOculto = `(${prefixo}) **** -${sufixo}`;

    return numeroOculto;
  }
}
