import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, take } from 'rxjs';
import { SindicatosService } from 'app/modules/gestao-sindical/sindicatos/sindicatos.service';

@Injectable({
  providedIn: 'root',
})
export class FilterSindicatosService {
  constructor(private _service: SindicatosService) {}

  private _sindicatos: BehaviorSubject<any> = new BehaviorSubject(null);

  get sindicatos$() {
    return this._sindicatos.asObservable();
  }

  async getSindicatos() {
    const res = await lastValueFrom(
      this._service.getSindicatos().pipe(take(1))
    );

    this._sindicatos.next(res);
  }
}
