<div class="absolute w-full h-full overflow-x-hidden a">
  <div class="flex flex-col items-center justify-center">
    <div id="chart" class="w-full">
      <apx-chart
        [chart]="chartOptions.chart"
        [title]="chartOptions.title"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke"
        [series]="chartOptions.series"
        [legend]="chartOptions.legend"
        [colors]="chartOptions.colors"
        [tooltip]="chartOptions.tooltip"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
      ></apx-chart>
    </div>

    <div
      class="absolute bottom-0 w-full h-20 bg-black flex flex-row items-center justify-around"
    >
      <span *ngIf="totais?.erros" class="text-white font-bold text-3xl">
        {{ totais?.erros }} erros totais
      </span>

      <span *ngIf="totais?.acertos" class="text-white font-bold text-3xl">
        {{ totais?.acertos }} acertos totais
      </span>
    </div>
  </div>
</div>
