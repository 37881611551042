import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as moment from 'moment';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LeiteBemService {
  private dataAdminTotal: BehaviorSubject<any>;
  private adminPage: BehaviorSubject<any>;
  private dairy: BehaviorSubject<any>;
  private indiceCepea: BehaviorSubject<any>;
  private indice: BehaviorSubject<any>;
  private estados: BehaviorSubject<any>;
  private usersData: BehaviorSubject<any>;
  private allUsersData: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient
  ) {
    moment.locale('pt-br');
    this.dataAdminTotal = new BehaviorSubject([]);
    this.adminPage = new BehaviorSubject([]);
    this.dairy = new BehaviorSubject([]);
    this.indiceCepea = new BehaviorSubject([]);
    this.indice = new BehaviorSubject([]);
    this.estados = new BehaviorSubject([]);
    this.usersData = new BehaviorSubject([]);
    this.allUsersData = new BehaviorSubject(null);
  }

  auth() {
    return this._httpClient.post(`${environment.oauthLeiteBem}/sessions`, { email: "infosindical@sistemafaeg.org.br", password: "1nf0s1nd14!" })
      .pipe(
        switchMap((items: any) => {
          return this._httpClient.post(`${environment.apiLeiteBem}/usuariosEmail`, { email: "infosindical@sistemafaeg.org.br" }).pipe(
            catchError((error) => {
              return throwError(error);
            }),
            switchMap((user) => {
              localStorage.setItem('adminToken', items.token);
              localStorage.setItem('Leitebem/dataUser', JSON.stringify(user[0]));
              return of(items.token);
            })
          )
          // const user = await lastValueFrom(this._httpClient.post(`${environment.apiLeiteBem}/usuariosEmail`, { email: "infosindical@sistemafaeg.org.br" }))
          // localStorage.setItem('adminToken', items.token);
          // localStorage.setItem('Leitebem/dataUser', JSON.stringify(user[0]));
          // return of(true);
        })
      );
  }

  get dataAdminTotal$(): Observable<any> {
    return this.dataAdminTotal.asObservable();
  }

  populateDataAdminTotal() {
    const dataAdminTotal = localStorage.getItem('dataAdminTotal');
    this.dataAdminTotal.next(dataAdminTotal ? JSON.parse(dataAdminTotal) : []);
  }

  get adminPage$(): Observable<any> {
    return this.adminPage.asObservable();
  }

  get dairy$(): Observable<any> {
    return this.dairy.asObservable();
  }

  get indiceCepea$(): Observable<any> {
    return this.indiceCepea.asObservable();
  }

  get indice$(): Observable<any> {
    return this.indice.asObservable();
  }

  get estados$(): Observable<any> {
    return this.estados.asObservable();
  }

  get usersData$(): Observable<any> {
    return this.usersData.asObservable();
  }

  populateUsersData() {
    const usersData = localStorage.getItem('usersData');
    this.usersData.next(usersData ? JSON.parse(usersData) : []);
  }

  get allUsersData$(): Observable<any> {
    return this.allUsersData.asObservable();
  }



  populateAllUsersData() {
    const allUsersData = localStorage.getItem('allUsersData');
    this.allUsersData.next(allUsersData ? JSON.parse(allUsersData) : []);
  }

  getAdminPage(clear = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    if (clear) {
      return of(this.adminPage.next([]));
    }
    return this._httpClient.get(`${environment.apiLeiteBem}/adminPage`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const adminPage = localStorage.getItem('adminPage');
          return of(adminPage ? JSON.parse(adminPage) : []);
        }),
        tap((items) => {
          localStorage.setItem('lastDateAdminPage', moment().format('LLL'));
          localStorage.setItem('adminPage', JSON.stringify(items));
          this.adminPage.next(items);
        })
      );
  }

  clearDataAdminTotal() {
    localStorage.setItem('dataAdminTotal', JSON.stringify([]));
    return of(this.dataAdminTotal.next([]));
  }

  getDataAdminTotal(dateInit = '2000-01-01', dateEnd = '2100-01-01') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/dataAdminTotal/${dateInit}/${dateEnd}`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const dataAdminTotal = localStorage.getItem('dataAdminTotal');
          return of(dataAdminTotal ? JSON.parse(dataAdminTotal) : []);
        }),
        tap((items) => {
          localStorage.setItem('lastDatedataAdminTotal', moment().format('LLL'));
          localStorage.setItem('dataAdminTotal', JSON.stringify(items));
          this.dataAdminTotal.next(items);
        })
      );
  }

  clearUsersData() {
    localStorage.setItem('usersData', JSON.stringify([]));
    return of(this.usersData.next([]));
  }

  getUsersData(dateInit = '2000-01-01', dateEnd = '2100-01-01') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/usersData/${dateInit}/${dateEnd}`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const usersData = localStorage.getItem('usersData');
          return of(usersData ? JSON.parse(usersData) : []);
        }),
        tap((items) => {
          localStorage.setItem('lastDateUsersData', moment().format('LLL'));
          localStorage.setItem('usersData', JSON.stringify(items));
          localStorage.setItem('nomePlaninha', `usuarios_${moment(dateInit).format('YYYY-MM-DD')}_${moment(dateEnd).format('YYYY-MM-DD')}`);
          this.usersData.next(items);
        })
      );
  }

  clearAllUsersData() {
    localStorage.setItem('allUsersData', JSON.stringify([]));
    return of(this.allUsersData.next([]));
  }

  getAllUsersData(dateInit = '2000-01-01', dateEnd = '2100-01-01') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/allUsersData/${dateInit}/${dateEnd}`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const allUsersData = localStorage.getItem('allUsersData');
          return of(allUsersData ? JSON.parse(allUsersData) : []);
        }),
        tap((items) => {
          localStorage.setItem('lastDateAllUsersData', moment().format('LLL'));
          localStorage.setItem('allUsersData', JSON.stringify(items));
          this.allUsersData.next(items);
        })
      );
  }

  getDairy() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/dairy`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const dairy = localStorage.getItem('dairy');
          return of(dairy ? JSON.parse(dairy) : []);
        }),
        tap((items) => {
          localStorage.setItem('dairy', JSON.stringify(items));
          this.dairy.next(items);
        })
      );
  }

  getIndiceCepea() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/indiceCepea`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const indiceCepea = localStorage.getItem('indiceCepea');
          return of(indiceCepea ? JSON.parse(indiceCepea) : []);
        }),
        tap((items) => {
          localStorage.setItem('indiceCepea', JSON.stringify(items));
          this.indiceCepea.next(items);
        })
      );
  }

  getIndice() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/indice`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const indice = localStorage.getItem('indice');
          return of(indice ? JSON.parse(indice) : []);
        }),
        tap((items) => {
          localStorage.setItem('indice', JSON.stringify(items));
          this.indice.next(items);
        })
      );
  }


  getEstados() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return this._httpClient.get(`${environment.apiLeiteBem}/estados`, { headers })
      .pipe(
        catchError((error) => {
          console.log(error);
          const estados = localStorage.getItem('estados');
          return of(estados ? JSON.parse(estados) : []);
        }),
        tap((items) => {
          localStorage.setItem('estados', JSON.stringify(items));
          this.estados.next(items);
        })
      );
  }

  liberarNovaVersao(): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiLeiteBem}/appVersion`, {}, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }


  getCidades(estadoId): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiLeiteBem}/cidadesId`, { estadoId }, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveDairy(dairy): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
      });
      this._httpClient.post(`${environment.apiLeiteBem}/dairy`, dairy, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  editDairy(dairy): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
      });
      this._httpClient.put(`${environment.apiLeiteBem}/dairy`, dairy, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteDairy(idDairy): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
      });
      this._httpClient.delete(`${environment.apiLeiteBem}/dairy/${idDairy}`, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveIndiceCepea(indiceCepea): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiLeiteBem}/indiceCepea`, indiceCepea, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  editIndiceCepea(indiceCepea, id): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiLeiteBem}/indiceCepea/${id}`, indiceCepea, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteIndiceCepea(id): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.apiLeiteBem}/indiceCepea/${id}`, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveIndice(indice): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiLeiteBem}/indice`, indice, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  editIndice(indice, id): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiLeiteBem}/indice/${id}`, indice, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteIndice(id): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
    });
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.apiLeiteBem}/indice/${id}`, { headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
}
