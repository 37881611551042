import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResumoCursosService {
  private _lista: BehaviorSubject<any> = new BehaviorSubject(null);
  private _progressos: BehaviorSubject<any> = new BehaviorSubject(null);
  private _selecionado: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  get selecionado$(): Observable<any> {
    return this._selecionado.asObservable();
  }

  get lista$(): Observable<any> {
    return this._lista.asObservable();
  }

  get progressos$(): Observable<any> {
    return this._progressos.asObservable();
  }

  get progressos(): any[] {
    return this._progressos.getValue();
  }

  set progressos(value: any) {
    this._progressos.next(value);
  }

  set selecionado(value: any) {
    this._selecionado.next(value);
  }

  set lista(value: any) {
    this._lista.next(value);
  }

  getResumo(body: any): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/pessoa/resumoCurso`, body)
      .pipe(
        tap((response: any) => {
          this._lista.next(response);
        })
      );
  }

  getProgressosById(idProgresso: any): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/campanha/getProgressosById/${idProgresso}`)
      .pipe(
        tap((response: any) => {
          this._progressos.next(response);
        })
      );
  }

  updateProgresso(body: any): void {
    this.progressos$
      .pipe(
        take(1),
        map((progressos: any[]) => {
          const index = progressos?.findIndex(
            (fin: any) =>
              fin?.resumo === body?.resumo &&
              fin?.tipoResumo === body?.tipoResumo
          );

          if (index !== -1) {
            const old = cloneDeep(progressos[index]);

            old.resposta = body.respostas;

            progressos[index] = old;
          } else {
            const neww = {
              id: null,
              aprovado: null,
              avaliacao: null,
              campanha: body?.campanha,
              criadoEm: new Date(),
              dataAgendamento: null,
              disparadoEm: null,
              duplicado: null,
              pessoa: null,
              promotor: null,
              respondido: true,
              resumo: body?.resumo,
              tipoResumo: body?.tipoResumo,
              resposta: body.respostas,
              disparo: null,
            };

            progressos.push(neww);
          }

          this._progressos.next(progressos);

          return true;
        })
      )
      .subscribe();
  }
}
