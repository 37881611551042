import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnChanges {
  @Input() data: Date;
  @Input() css: string;
  @Input('label') label;
  @Input('min') min = null;
  @Input('max') max = null;
  @Input('required') required;
  @Input('placeholder') placeholder;
  @Input('disabled') disabled = false;
  @Input('appearance') appearance = 'fill';
  @Input('dataInicial') dataInicial = null;
  @Input() resetar: Subject<any> = new Subject<any>();
  @Output('changedDate') changedDateEmitter = new EventEmitter();

  id = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
  mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor() {}

  get value(): Date {
    return this.data;
  }

  set value(v: Date) {
    if (v && v.toString() != 'Invalid Date') {
      if (v !== this.data) {
        if (this.max != null && v > this.max) {
          this.data = null;
          this.changedDateEmitter.emit({
            status: 'error',
            content: 'A data informada é maior do que a data limite.',
            data: this.data,
          });
          (document.getElementById(this.id) as HTMLInputElement).value = null;
        } else if (this.min != null && v < this.min) {
          this.data = null;
          this.changedDateEmitter.emit({
            status: 'error',
            content: 'A data informada é menor do que a data limite.',
            data: this.data,
          });
          (document.getElementById(this.id) as HTMLInputElement).value = null;
        } else {
          this.data = v;
          this.changedDateEmitter.emit({
            status: 'success',
            content: this.data,
          });
        }
      }
    } else {
      this.data = null;
      this.changedDateEmitter.emit({
        status: 'error',
        content:
          'A data informada é inválida, por favor insira uma data válida.',
        data: this.data,
      });
      (document.getElementById(this.id) as HTMLInputElement).value = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataInicial == null) {
      if ((document.getElementById(this.id) as HTMLInputElement) != null) {
        (document.getElementById(this.id) as HTMLInputElement).value = null;
      }
    } else if (this.dataInicial != this.data) {
      if (typeof this.dataInicial == 'object') {
        this.data = this.dataInicial;
      } else {
        const dataFormatada = new Date(
          this.dataInicial.split('T')[0].replace(/-/g, '/')
        );

        if (dataFormatada.toString() != 'Invalid Date') {
          this.data = dataFormatada;
        }
      }
    }
  }

  ngOnInit(): void {
    this.resetar.subscribe((res) => {
      if (res) {
        this.resett();
      }
    });
  }

  resett(): void {
    this.value = null;
  }

  toDate(value): void {
    if (value != '') {
      const arrayValue = value.split('/');
      const dateFormated =
        arrayValue[1] + '/' + arrayValue[0] + '/' + arrayValue[2];
      this.value = new Date(dateFormated);
    } else {
      this.data = null;
      this.changedDateEmitter.emit({
        status: 'error',
        content:
          'A data informada é inválida, por favor insira uma data válida.',
        data: this.data,
      });
    }
  }
}
