import { Component, Inject, OnInit } from '@angular/core';
import { CampanhaService } from '../../../campanha/campanha.service';
import { optionsLabelValue } from 'app/core/utils/utils';
import { Campanha } from 'app/model/campanha';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseUtilsService } from '@fuse/services/utils';

export class FiltroChat {
  campanhaId: number = null;
  numero: string = null;
}

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {
  campanhas = [];
  numeros = [];
  filtro: FiltroChat = {
    campanhaId: null,
    numero: null
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public _matDialogRef: MatDialogRef<FiltrosComponent>,
    private _campanhaService: CampanhaService,
    private _utils: FuseUtilsService
  ) { }

  ngOnInit(): void {
    this._campanhaService.campanhas$.subscribe(
      (campanhas: Campanha) => {
        this.campanhas = optionsLabelValue(campanhas, 'nome', 'id')
      }
    );
    this._utils.numerosConfigurados$.subscribe((numeros: any[]) => {
      numeros = numeros.map(numero => {
        return {
          value: numero.numero,
          label: `${numero.numero} - (${numero.nome})`,
        }
      })
      this.numeros = optionsLabelValue(numeros, 'label', 'value')
    });
    if (this.data.filtro) {
      this.filtro = this.data.filtro;
    }
  }

  limparFiltro(key) {
    this.filtro[key] = null
  }

  fechar() {
    this._matDialogRef.close();
  }

  filtrar() {
    this._matDialogRef.close(this.filtro);
  }

}
