import { filter } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { NavigationStart, Router } from '@angular/router';
import { DominioService } from './shared/services/dominio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private user: AuthService,
    private _dominio: DominioService
  ) {
    this._router.events
      .pipe(
        filter((event: any) => {
          this.inserirTextoPadrao();

          return event instanceof NavigationStart;
        })
      )
      .subscribe((res: any) => {
        if (res?.id === 1) {
          const bol = res?.url === '/';
          const init = this._dominio.getRotaInicial;

          if (this.user?.userValue && bol && init === 'home') {
            this._router.navigate(['dashboards']);
          }
        }
      });

    // firebase.initializeApp(environment.firebaseConfig);
  }

  ngOnInit(): void {
    // const messaging = firebase.messaging();
    // Notification.requestPermission()
    //   .then(() => {
    //     messaging
    //       .getToken({
    //         vapidKey: 'BM7JBFMEdqiC3KFuBcluoxA7uxLEoHwspd72Z_tLogX-K6pnNsT_68uI6ChkFX9-_0AV6MocDO81D3tvzoJ24oA',
    //       })
    //       .then((res) => console.log(res))
    //       .catch((err) => console.log(err));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // console.log(messaging);
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    // PushNotifications.requestPermissions().then(result => {
    //     if (result.receive === 'granted') {
    //         // Register with Apple / Google to receive push via APNS/FCM
    //         PushNotifications.register();
    //     } else {
    //         // Show some error
    //     }
    // });
    // On success, we should be able to receive notifications
    // PushNotifications.addListener('registration',
    //     (token: Token) => {
    //         console.log('Push registration success, token: ' + token.value);
    //         this.user.setNotificacao(token);
    //     }
    // );
    // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //     (error: any) => {
    //         console.log('Error on registration: ' + JSON.stringify(error));
    //     }
    // );
    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //     (notification: PushNotificationSchema) => {
    //         console.log('Push received: ' + JSON.stringify(notification));
    //     }
    // );
    // Method called when tapping on a notification
    // PushNotifications.addListener('pushNotificationActionPerformed',
    //     (notification: ActionPerformed) => {
    //         console.log('Push action performed: ' + JSON.stringify(notification));
    //     }
    // );
  }

  inserirTextoPadrao() {
    const spans = document.getElementsByClassName('tituloTotal');
    const textoPadrao = this._dominio.getTitulo;

    for (var i = 0; i < spans.length; i++) {
      if (spans[i]) {
        spans[i].textContent = textoPadrao;
      }
    }
  }
}
