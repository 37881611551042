import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { GraficoDinamicaService } from './grafico-dinamica.service';

@Injectable({
  providedIn: 'root',
})
export class GraficoDinamicaResolver implements Resolve<any> {
  constructor(private _service: GraficoDinamicaService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const resolvers = [
      this._service.getDinamicaConfig(route.params.id),
      this._service.getDinamicaTotais(route.params.id),
      this._service.getDinamicaParticipantes(route.params.id),
    ];

    return forkJoin(resolvers);
  }
}
