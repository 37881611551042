import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AceitarTermosService } from './aceitar-termos.service';

@Injectable({
    providedIn: 'root'
})
export class VerificarAceiteTermoResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _aceitarTermosService: AceitarTermosService, private _router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const redirectURL = state.url === '/sign-out' ? '/' : state.url;
        return this._aceitarTermosService.getTermoAceite()
            .pipe(
                switchMap((termoAceite) => {
                    if (termoAceite) {
                        // Allow the access
                        return of(true);
                    } else {
                        // Redirect to the sign-in page
                        this._router.navigate(['aceitar-termos'], { queryParams: { redirectURL } });
                        // Prevent the access
                        return of(false);
                    }
                })
            )
    }
}

@Injectable({
    providedIn: 'root'
})
export class TermoResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _aceitarTermosService: AceitarTermosService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this._aceitarTermosService.getTermo();
    }
}
