import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, take, tap } from 'rxjs';

@Component({
  selector: 'app-get-regiao',
  templateUrl: './get-regiao.component.html',
})
export class GetRegiaoComponent implements OnInit {
  constructor(
    private _httpClient: HttpClient,
    private _change: ChangeDetectorRef
  ) {}
  @Input() id: any;

  nome: any;

  ngOnInit(): void {
    if (this.id) {
      this.getById(this.id)
        .pipe(take(1))
        .subscribe(
          (res) => {
            if (res) {
              this.nome = res?.nome;

              this._change.markForCheck();
            }
          },
          () => {}
        );
    }
  }

  getById(id: any): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/municipioMicroRegiao/getById/${id}`)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap((res) => {
          return res;
        })
      );
  }
}
