import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextMaskModule } from 'angular2-text-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { AsidePanelModule } from 'app/modules/auth/aside-panel/aside-panel.module';
import { SelectSindicatoModule } from './components/select-sindicato/select-sindicato.module';
import { SelectMunicipioComponent } from './components/select-municipio/select-municipio.component';
import { DateComponent } from './components/date/date.component';
import { AutocompleteMunicipioComponent } from './components/autocomplete-municipio/autocomplete-municipio.component';

const sharedModules = [
  CommonModule,
  MatFormFieldModule,
  MatInputModule,
  FormsModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatTooltipModule,
];

@NgModule({
  imports: [
    ...sharedModules,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TextMaskModule,
    MatFormFieldModule,
    MatInputModule,
    AsidePanelModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
    SelectSindicatoModule,
  ],
  exports: [
    ...sharedModules,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateComponent,
    AsidePanelModule,
    NgxMaskModule,
    SelectMunicipioComponent,
    SelectSindicatoModule,
    AutocompleteMunicipioComponent,
  ],
  declarations: [DateComponent, SelectMunicipioComponent, AutocompleteMunicipioComponent],
})
export class SharedModule {}
