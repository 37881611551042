import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MenusService } from 'app/layout/menus.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'compact-layout',
  templateUrl: './compact.component.html',
  styleUrls: ['./compact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
  user: any;
  menus = [];
  rota: string;
  isHome: boolean;
  hidden: Boolean;
  blocked: boolean;
  menuLeitebem: any;
  isLeiteBem: boolean;
  isMapa: boolean;
  isScreenSmall: boolean;
  navigation: Navigation;
  blocks = [
    '/home',
    '/form',
    '/company',
    '/sign-in',
    '/sign-up',
    '/promotor',
    '/reset-password',
    '/forgot-password',
    '/confirmation-required',
  ];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private menuService: MenusService,
    @Inject(DOCUMENT) private document: Document,
    private _navigationService: NavigationService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService
  ) {
    this.rota = this._router.url;
    this.isHome = this.rota === '/home';
    this.isLeiteBem = this.rota.includes('/leitebem');
    this.isMapa = this.rota.includes('/crm/mapa');
    this.menus = this.menuService.menus;
    this.menuLeitebem = this.menuService.menuLeitebem;

    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this.isScreenSmall = matchingAliases.length <= 1;
      });

    if (this.compara()) {
      this.blocked = true;
    }

    this._navigationService.hidden$.subscribe((res) => {
      this.hidden = res;
    });

    this._router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: ResolveEnd) => {
        this.isHome = res.url === '/home';
        this.isLeiteBem = res.url.includes('/leite-bem');
      });

    this.user = this._auth?.userValue;

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  scrollTo(id): void {
    const elem = document.getElementById(id);

    if (this.rota !== '/home') {
      this._router.navigate(['home']).then((res) => {
        if (res) {
          setTimeout(() => {
            this.scrollTo(id);
          }, 100);
        }
      });
    }

    if (!elem) {
      return;
    }

    elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  compara(): boolean {
    let y = 0;

    this.blocks.map((res) => {
      if (this.rota.includes(res)) {
        y++;
      }
    });

    if (y > 0) {
      return true;
    }

    return false;
  }

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    if (this.compara() && !this.isScreenSmall) {
      return;
    }
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  tratarFilho(dados: any): any {
    dados.children = dados?.children?.filter((fil) => {
      const set = dados?.children?.find((fin) => fin?.state === fil?.id);

      if (set?.children?.length) {
        this.tratarFilho(set);
      }

      if (set) {
        return true;
      }
    });
  }

  routeMenu(): boolean {
    if (this._router.url.includes('leite-bem')) {
      return true;
    } else {
      return false;
    }
  }
}
