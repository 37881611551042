<div class="flex flex-col items-center w-full">
  <section *ngIf="!formweb" class="w-full flex flex-row justify-between -mr-6 -mb-3">
    <div>
      <h2 *ngIf="promotor?.pessoa?.nome" class="font-bold text-xl mt-3 mb-5">
        Entrevistador :

        <span class="font-bold text-lg capitalize">
          {{ promotor?.pessoa?.nome }}

          {{ faegJovem ? '   ( ' + faegJovem?.nome + ' ) ' : '' }}
        </span>
      </h2>
    </div>

    <button class="mb-2" mat-icon-button (click)="fechar()" color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </section>

  <div
    *ngIf="['Telemarketing', 'Whatsapp'].includes(campanha?.tipo)"
    class="w-full border-2 rounded-md grid grid-cols-1 md:grid-cols-2 gap-4 p-5 my-3"
  >
    <div class="h-full w-full flex flex-col">
      <span *ngIf="!editMode" class="text-xl font-normal">
        {{ cliente?.nome }}
      </span>

      <div *ngIf="editMode" class="w-full mb-1">
        <div class="w-full">
          <span class="text-md font-semibold items-center"> Nome : </span>
        </div>

        <mat-form-field class="w-full">
          <input matInput [(ngModel)]="cliente.nome" />
        </mat-form-field>
      </div>

      <span class="text-md font-semibold items-center">
        CPF:
        <span *ngIf="!editMode">
          {{ cliente?.cpf | cpfCnpj }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput mask="000.000.000-00" [(ngModel)]="cliente.cpf" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold" *ngIf="cliente?.celular || (!cliente?.celular && !cliente?.telefone)">
        Número:
        <span *ngIf="!editMode">
          {{ cliente?.celular | phone }}
        </span>
        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="cliente.celular" mask="(00) 0000-0000||(00) 0 0000-0000" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold" *ngIf="!cliente?.celular && cliente?.telefone">
        Número:
        <span *ngIf="!editMode">
          {{ cliente?.telefone | phone }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="cliente.telefone" mask="(00) 0000-0000||(00) 0 0000-0000" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold">
        Email:
        <span *ngIf="!editMode">
          {{ cliente?.email ? cliente.email : 'Não informado' }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="cliente.email" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold">
        Endereço:
        <span *ngIf="!editMode">
          {{ cliente?.endereco ? cliente.endereco : 'Não informado' }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="cliente.endereco" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold">
        Bairro:
        <span *ngIf="!editMode">
          {{ cliente?.bairro ? cliente.bairro : 'Não informado' }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input matInput [(ngModel)]="cliente.bairro" />
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold">
        Município:
        <span *ngIf="!editMode">
          {{ cliente?.municipio ? cliente.municipio.descricao + ' - ' + cliente.municipio.uf : 'Não informado' }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <mat-form-field class="w-full">
            <input
              matInput
              [value]="txtMunicipio"
              [formControl]="controlMunicipio"
              [matAutocomplete]="autoComplete1"
            />
            <mat-autocomplete
              matNativeControl
              #autoComplete1="matAutocomplete"
              name="autoComplete"
              [displayWith]="displayFnMunicipio"
              (optionSelected)="setMunicipio($event.option.value)"
            >
              <mat-option *ngFor="let m of municipios" [value]="m">
                {{ m.descricao }} - {{ m.uf }}
              </mat-option>
              <mat-option *ngIf="!municipios.length" disabled>
                <span>Sem resultados</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </span>

      <span class="text-md font-semibold">
        Vínculo meio rural:
        <span *ngIf="!editMode">
          {{ cliente?.vinculoMeioRural ? cliente.vinculoMeioRural.descricao : 'Não informado' }}
        </span>

        <div *ngIf="editMode" class="w-full mb-1">
          <autocomplete-select [options]="vinculoMeioRural" [(ngModel)]="cliente.vinculoMeioRural"></autocomplete-select>
        </div>
      </span>

      <button
        mat-mini-fab
        color="primary"
        *ngIf="!editMode"
        (click)="activeEdit()"
        [matTooltip]="'Atualizar dados'"
        class="mt-5 self-center sm:self-start sm:ml-15"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <div *ngIf="editMode" class="mt-5 self-center sm:self-start sm:ml-10 flex flex-row gap-6">
        <button mat-mini-fab color="warn" (click)="cancelEdit()" [matTooltip]="'Cancelar'">
          <mat-icon>close</mat-icon>
        </button>

        <button mat-mini-fab color="primary" (click)="saveEdit()" [matTooltip]="'Salvar'">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>

    <div class="flex justify-center items-center gap-2" *ngIf="campanha?.tipo === 'Telemarketing'">
      <div class="h-full flex flex-col mt-5 sm:mt-0"></div>

      <div class="h-full flex flex-col mt-2">
        <div class="flex flex-col gap-2 mx-2">
          <button class="mb-3" color="primary" mat-raised-button (click)="getResumo()">
            Resumo
            <mat-icon class="icon-size-6 ml-3"> assignment </mat-icon>
          </button>

          <button
            class="mb-3"
            color="warn"
            mat-raised-button
            (click)="removerInteresse()"
            *ngIf="!data?.resposta?.length && data?.aprovado !== false && campanha?.tipo === 'Telemarketing'"
          >
            Não possui interesse
            <mat-icon class="icon-size-6 ml-3"> not_interested </mat-icon>
          </button>

          <button
            class="mb-3"
            color="accent"
            mat-raised-button
            (click)="setDataAgendamento(' ')"
            *ngIf="!data?.respondido && !data?.dataAgendamento && data?.aprovado !== false && campanha?.tipo === 'Telemarketing'"
          >
            Agendar

            <mat-icon class="icon-size-6 ml-3"> date_range </mat-icon>
          </button>
          <app-date
            #inicioD
            name="data"
            label="Data do agendamento"
            [dataInicial]="data?.dataAgendamento"
            (changedDate)="setDataAgendamento(inicioD.value)"
            *ngIf="!data?.respondido && data?.dataAgendamento && data?.aprovado !== false && campanha?.tipo === 'Telemarketing'"
          >
          </app-date>

          <button
            class="mb-3 bg-blue-600 text-white"
            mat-flat-button
            (click)="definirPessoaNaoVinculada()"
            [ngClass]="{ 'opaque-button': data.pesosaNaoVinculada }"
            [disabled]="data.pesosaNaoVinculada"
          >
            <span class="text-white">Pessoa não vinculada</span>
            <mat-icon class="icon-size-6 ml-3 text-white">check_circle</mat-icon>
          </button>
          <button
            class="mb-3 bg-blue-600 text-white"
            mat-flat-button
            (click)="definirCaixaPostal()"
            [ngClass]="{ 'opaque-button': data.caixaPostal }"
            [disabled]="data.caixaPostal"
          >
            <span class="text-white">Caixa Postal</span>
            <mat-icon class="icon-size-6 ml-3 text-white">check_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full" *ngIf="formulario?.length">
    <div class="flex flex-col items-center w-full mb-8" *ngFor="let dado of formulario; let i = index">
      <span class="capitalize text-lg font-bold w-full">
        {{ dado.pergunta }}

        <span *ngIf="dado?.obrigatorio" class="text-lg font-normal ml-4"> * </span>
      </span>

      <ng-container>
        <mat-form-field *ngIf="dado.tipo === 'livre'" class="fuse-mat-textarea flex-auto w-full mt-3">
          <textarea rows="6" matInput [readonly]="leitura" [(ngModel)]="respostas[dado.id]"></textarea>
        </mat-form-field>

        <div *ngIf="dado.tipo === 'SN'" class="flex p-4 w-full">
          <div class="flex items-center justify-center mx-auto p-2 rounded-full shadow-lg bg-primary">
            <mat-button-toggle-group [readonly]="leitura" [(ngModel)]="respostas[dado.id]">
              <mat-button-toggle class="bg-primary" value="nao">
                <mat-icon class="mr-2 text-white">close</mat-icon>

                <span class="mr-1 text-white">Não</span>
              </mat-button-toggle>

              <mat-button-toggle class="bg-primary" value="sim">
                <span class="ml-1 text-white">Sim</span>

                <mat-icon class="ml-2 text-white">check</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <mat-form-field *ngIf="dado.tipo === 'data'" class="flex-auto w-full mt-3">
          <input matInput mask="00/00/0000" [readonly]="leitura" [(ngModel)]="respostas[dado.id]" />
        </mat-form-field>

        <mat-form-field *ngIf="dado.tipo === 'cursoRm'" class="fuse-mat-textarea flex-auto w-full mt-3">
          <textarea rows="6" matInput [readonly]="leitura" [(ngModel)]="respostas[dado.id]"></textarea>
        </mat-form-field>

        <div *ngIf="dado.tipo === 'multi'" class="flex flex-col w-full">
          <mat-radio-group [readonly]="leitura" [(ngModel)]="respostas[dado.id]" class="flex flex-col gap-2 mt-3 ml-6 items-start justify-center">
            <mat-radio-button color="warn" [value]="item" *ngFor="let item of dado.opcoes">
              <span class="capitalize font-semibold"> {{ item }}</span>
            </mat-radio-button>
          </mat-radio-group>

          <div class="mt-6 mb-4" [readonly]="leitura" *ngIf="!dado?.opcoes?.length">
            <mat-radio-group color="warn" class="space-x-2" [(ngModel)]="respostas[dado.id]">
              <mat-radio-button value="0">0</mat-radio-button>

              <mat-radio-button value="1">1</mat-radio-button>

              <mat-radio-button value="2">2</mat-radio-button>

              <mat-radio-button value="3">3</mat-radio-button>

              <mat-radio-button value="4">4</mat-radio-button>

              <mat-radio-button value="5">5</mat-radio-button>

              <mat-radio-button value="6">6</mat-radio-button>

              <mat-radio-button value="7">7</mat-radio-button>

              <mat-radio-button value="8">8</mat-radio-button>

              <mat-radio-button value="9">9</mat-radio-button>

              <mat-radio-button value="10">10</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div *ngIf="dado?.tipo === 'coordenadas'" class="flex flex-row mt-3 gap-3 w-full items-center">
          <section class="w-full flex flex-col">
            <section class="w-full px-6">
              <mat-form-field class="fuse-mat-textarea flex-auto w-full">
                <mat-label>Latitude 1</mat-label>

                <input #lat matInput [readonly]="leitura" mask="-999999999" />
              </mat-form-field>

              <mat-form-field class="fuse-mat-textarea -mt-3 flex-auto w-full">
                <mat-label>Longitude</mat-label>

                <input #lon matInput [readonly]="leitura" mask="-999999999" />
              </mat-form-field>

              <div *ngIf="setCord(respostas[dado?.id], lat, lon)"></div>
            </section>
          </section>

          <section>
            <button color="primary" mat-raised-button (click)="openMap(respostas[dado?.id])">Abrir Mapa</button>
          </section>
        </div>

        <div *ngIf="dado.tipo === 'cadeia'" class="flex p-4 w-full">
          <div class="flex items-center justify-center w-full">
            <mat-form-field class="w-full">
              <mat-select [readonly]="leitura" [(ngModel)]="respostas[dado.id]">
                <mat-option value="{{ dado?.id }}" *ngFor="let dado of cadeias">
                  {{ dado?.nome }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="dado.tipo === 'regiao'" class="flex p-4 w-full">
          <app-get-regiao class="w-full" [id]="respostas[dado.id]"></app-get-regiao>
        </div>

        <div *ngIf="dado.tipo === 'foto'" class="flex flex-col items-center justify-center gap-6 p-4 w-full">
          <button (click)="openFoto(dado.id)">
            <mat-icon svgIcon="linked_camera" style="z-index: 100; transform: scale(3); color: #63b45aad"></mat-icon>
          </button>

          <span class="font-bold text-2xl">VER FOTO</span>
        </div>

        <div *ngIf="dado.tipo === 'sindicato'" class="flex p-4 w-full">
          <app-filter-sindicatos class="w-full" [id]="respostas[dado.id]" (idSelecionado)="set(dado.id, $event)"></app-filter-sindicatos>
        </div>
      </ng-container>

      <ng-container *ngIf="dado.tipo === 'padrao'" class="w-full">
        <mat-form-field *ngIf="dado.id === 26" class="flex-auto w-full mt-3 mx-10">
          <input matInput [readonly]="leitura" [value]="tratarData(respostas[dado.id])" />
        </mat-form-field>

        <mat-form-field class="flex-auto w-full mt-3" *ngIf="dado.id === 27 || dado.id === 24">
          <input matInput [readonly]="leitura" [value]="respostas[dado.id]" />
        </mat-form-field>

        <mat-form-field class="flex-auto w-full mt-3" *ngIf="dado.id === 25">
          <input matInput [readonly]="leitura" [(ngModel)]="respostas[dado.id]" mask="(00) 0000-0000||(00) 0 0000-0000" />
        </mat-form-field>

        <mat-form-field class="flex-auto w-full mt-3" *ngIf="dado.id === 28">
          <input matInput type="phone" [readonly]="leitura" mask="000.000.000-00" [(ngModel)]="respostas[dado.id]" />
        </mat-form-field>

        <div class="flex-auto w-full mt-3" *ngIf="dado.id === 23">
          <app-get-municipio [id]="respostas[dado.id]"></app-get-municipio>
        </div>

        <div class="mt-3 flex items-center justify-center mx-auto p-2 rounded-full shadow-lg bg-primary" *ngIf="dado.id === 22">
          <mat-button-toggle-group [disabled]="leitura" [(ngModel)]="respostas[dado.id]">
            <mat-button-toggle class="bg-primary" value="M">
              <span class="mr-1 text-white">Masculino</span>
            </mat-button-toggle>

            <mat-button-toggle class="bg-primary" value="F">
              <span class="ml-1 text-white">Feminino</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- botoes finais -->

<div class="flex items-center justify-around mt-4 gap-4" *ngIf="campanha?.tipo !== 'Evento' && campanha?.tipo !== 'Presencial'">
  <button mat-flat-button [color]="'accent'" (click)="fechar()" *ngIf="!ocultar.includes('fechar')">Fechar</button>

  <button mat-flat-button (click)="save()" [color]="'primary'" *ngIf="!ocultar.includes('salvar')">Salvar</button>
</div>

<div class="flex items-center justify-around mt-4 gap-4" *ngIf="leitura">
  <button mat-flat-button (click)="aprovar(false)" [color]="'warn'">Reprovar</button>

  <button mat-flat-button (click)="aprovar(true)" [color]="'primary'">Aprovar</button>
</div>
