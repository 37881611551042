import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SindicatosService {
  private _sindicatos: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getSindicatos(): Observable<any> {
    return this._httpClient.get(`${environment.api}/grupoFiliacao/tot`).pipe(
      tap((response: any) => {
        this._sindicatos.next(response);

        return response;
      })
    );
  }
}
