import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, take, tap } from 'rxjs';

@Component({
  selector: 'app-get-municipio',
  templateUrl: './get-municipio.component.html',
})
export class GetMunicipioComponent implements OnInit {
  constructor(
    private _httpClient: HttpClient,
    private _change: ChangeDetectorRef
  ) {}
  @Input() id: any;

  nome: any;

  ngOnInit(): void {
    this.getById(this.id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res) {
            this.nome = res?.nome + ' - ' + res?.uf;

            this._change.markForCheck();
          }
        },
        () => {}
      );
  }

  getById(id: any): Observable<any> {
    return this._httpClient.get(`${environment.api}/municipio/byId/${id}`).pipe(
      catchError((error) => {
        throw error;
      }),
      tap((res) => {
        return res;
      })
    );
  }
}
