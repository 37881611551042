<section>
  <fuse-card
    [class]="css[i]"
    (click)="selecionar(dado)"
    *ngFor="let dado of itens; let i = index; trackBy: trackByFn"
  >
    <div class="flex items-center">
      <div class="flex flex-col w-full">
        <span
          [innerText]="dado?.result"
          class="text-xl text-white font-medium tracking-tight leading-6 break-words w-full"
        ></span>

        <div class="w-full flex justify-end mt-2">
          <span
            class="text-white font-medium border border-slate-500 rounded-xl px-2"
          >
            Data: {{ dado?.data | date : "dd/MM/yyyy" }}
          </span>
        </div>
      </div>
    </div>
  </fuse-card>

  <ng-container *ngIf="!itens?.length">
    <fuse-card
      class="relative flex flex-col flex-auto p-6 bg-gray-700 shadow mx-5"
    >
      <div class="flex items-center">
        <div class="flex flex-col w-full">
          <div
            class="text-2xl text-white font-medium tracking-tight leading-6 break-words w-full"
          >
            Sem Informações
          </div>
        </div>
      </div>
    </fuse-card>
  </ng-container>
</section>
