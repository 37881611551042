import { BehaviorSubject, map, Observable, of, switchMap, take, tap, catchError, throwError } from 'rxjs';
import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Gerenciador } from './gerenciador.types';
import { environment } from 'environments/environment';
import { ResumoCursosService } from './list/modals/details/resumo-cursos/resumo-cursos.service';
import { CampanhaProgresso } from 'app/model/campanha-progresso';
import { GetAnalise } from './models/get-analise';

@Injectable({
  providedIn: 'root',
})
export class GerenciadorService {
  private _campanha: BehaviorSubject<any> = new BehaviorSubject(null);
  private _contato: BehaviorSubject<Gerenciador> = new BehaviorSubject(null);
  private _contatos: BehaviorSubject<Gerenciador[]> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient, private _resumo: ResumoCursosService) {}

  get contatos$(): Observable<Gerenciador[]> {
    return this._contatos.asObservable();
  }

  get contato$(): Observable<Gerenciador> {
    return this._contato.asObservable();
  }

  get campanha$(): Observable<Gerenciador> {
    return this._campanha.asObservable();
  }

  getProgressos(pagina: number, id: any): Observable<Gerenciador[]> {
    return this._httpClient.get<any[]>(`${environment.api}/campanha/progressoById/${pagina}/${id}`).pipe(
      tap((response: any[]) => {
        // response --> CampanhaProgresso
        this._contatos.next(response);
      })
    );
  }

  definirPessoaNaoVinculada(camapnhaProgresso: CampanhaProgresso): Observable<void> {
    console.log(camapnhaProgresso);
    return this._httpClient
      .get<void>(`${environment.api}/campanhaProgresso/salvarPessoaNaoVinculada/${camapnhaProgresso.id}`)
      .pipe(catchError((error) => of(error)));
  }

  definirCaixaPostal(camapnhaProgresso: CampanhaProgresso): Observable<void> {
    console.log(camapnhaProgresso);
    return this._httpClient
      .get<void>(`${environment.api}/campanhaProgresso/salvarCaixaPostal/${camapnhaProgresso.id}`)
      .pipe(catchError((error) => of(error)));
  }

  getCursos(idPessoa: number, inicio: string, fim: string): Observable<any> {
    return this._httpClient.get(`${environment.api}/curso/filtrar/${idPessoa}/${inicio}/${fim}`).pipe(
      catchError((error) => of(error)),
      tap((response: any) => response)
    );
  }

  addPages(pagina: number, id: any) {
    return this._httpClient.get<any[]>(`${environment.api}/campanha/progressoById/${pagina}/${id}`).pipe(
      tap((response: Gerenciador[]) => {
        console.log(response.splice(1, 10));
        const currentArray = this._contatos.getValue();
        const updatedArray = currentArray.concat(response);
        this._contatos.next(updatedArray);
      })
    );
  }

  getCampanha(id: any): Observable<any> {
    return this._httpClient.get<any[]>(`${environment.api}/campanha/campanhaById/${id}`).pipe(
      tap((response: any[]) => {
        this._campanha.next(response);
      })
    );
  }

  getByCampanhaUUID(campanha: any, UUID: any): Observable<any> {
    return this._httpClient.get<any[]>(`${environment.api}/campanha/getByCampanhaUUID/${campanha}/${UUID}`).pipe(
      tap((response: any[]) => {
        this._campanha.next(response);
      })
    );
  }

  createProgresso(body: any) {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post(`${environment.api}/campanha/postCampanhaProgresso`, body).pipe(
          map((contato) => {
            this._contatos.next([...contatos, contato]);
            return contato;
          })
        )
      )
    );
  }

  createProgressoList(campanhaId: number, body: any) {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post(`${environment.api}/campanha/createProgressoList/${campanhaId}`, body).pipe(
          map((inseridos: any[]) => {
            this._contatos.next([...inseridos, ...contatos]);
            return inseridos;
          })
        )
      )
    );
  }

  saveResposta(body: any) {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post<any>(`${environment.api}/campanha/resposta`, body).pipe(
          map((updatedContato: any) => {
            if (contatos?.length) {
              const index = contatos?.findIndex((item) => item?.id === updatedContato[0]?.progresso);
              const att = cloneDeep(contatos[index]);
              att.resposta = updatedContato;
              contatos[index] = att;
              this._contatos.next(cloneDeep(contatos));
            }

            return updatedContato;
          })
        )
      )
    );
  }

  saveTelemarketing(body: any) {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post<any>(`${environment.api}/campanha/saveTelemarketing`, body).pipe(
          map(() => {
            const progressos = this._resumo.progressos;

            const primario = progressos?.find((fin: any) => fin?.pessoa);

            const index = contatos.findIndex((item: any) => item.id === primario?.id);

            const att = cloneDeep(contatos[index]);

            att.respondido = true;

            contatos[index] = att;

            this._resumo.updateProgresso(body);

            this._contatos.next(cloneDeep(contatos));

            return true;
          })
        )
      )
    );
  }

  /**
   * Get notes
   */
  saveProgresso(body: any, id: any): Observable<any[]> {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post<any[]>(`${environment.api}/campanha/saveCampanhaProgresso/${id}`, body).pipe(
          map((updatedContato: any) => {
            const index = contatos.findIndex((item) => item.id === id);

            const att = cloneDeep(contatos[index]);

            att.resposta = body;

            contatos[index] = att;

            this._contatos.next(cloneDeep(contatos));

            return updatedContato;
          })
        )
      )
    );
  }

  reprovarProgresso(avaliacao: String, id: any): Observable<any> {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post<any[]>(`${environment.api}/campanha/reprovarProgresso/${id}`, avaliacao).pipe(
          map((updatedContato: any) => {
            const index = contatos.findIndex((item: any) => item.id === id);

            const att = cloneDeep(contatos[index]);

            att.aprovado = false;

            contatos[index] = att;

            this._contatos.next(cloneDeep(contatos));

            return updatedContato;
          })
        )
      )
    );
  }

  updateListGerenciador(id: number, updateDados: { [key: string]: string }): Observable<any> {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos: any) => {
        console.log(contatos);

        const index = contatos.findIndex((item: any) => item.id === id);

        const update = updateDados;

        if (update && index !== -1) {
          const att = cloneDeep(contatos[index]);

          Object.keys(update).map((keyy) => {
            att[keyy] = update[keyy];
          });

          contatos[index] = att;

          this._contatos.next(cloneDeep(contatos));
        }

        return contatos;
      })
    );
  }

  updateCampanhaGerenciador(updateDados: { [key: string]: string }): boolean {
    const antigo = this._campanha.getValue();

    const dados = updateDados;

    if (dados && antigo) {
      Object.keys(dados).map((keyy) => {
        antigo[keyy] = dados[keyy];
      });

      this._campanha.next(cloneDeep(antigo));
    }

    return true;
  }

  /**
   * Get notes
   */
  aprovarProgresso(id: any): Observable<any> {
    return this.contatos$.pipe(
      take(1),
      switchMap((contatos) =>
        this._httpClient.post<any[]>(`${environment.api}/campanha/aprovarProgresso/${id}`, {}).pipe(
          map((updatedContato: any) => {
            const index = contatos.findIndex((item) => item.id === id);

            const att = cloneDeep(contatos[index]);

            att.aprovado = true;

            contatos[index] = att;

            this._contatos.next(cloneDeep(contatos));

            return updatedContato;
          })
        )
      )
    );
  }

  /**
   * Get note by id
   */
  getGerenciadorById(id: Number): Observable<Gerenciador> {
    return this._contatos.pipe(
      take(1),
      map((notes) => {
        // Find within the folders and files
        const note = notes.find((value) => value.id === id) || null;

        // Update the note
        this._contato.next(note);

        // Return the note
        return note;
      }),
      switchMap((note) => {
        if (!note) {
          return throwError('Could not found the note with id of ' + id + '!');
        }

        return of(note);
      })
    );
  }

  deleteProgresso(id: Number) {
    return this.contatos$.pipe(
      take(1),
      switchMap((messages) =>
        this._httpClient.delete(`${environment.api}/campanha/campanhaProgresso/${id}`).pipe(
          map((isDelete: Boolean) => {
            const index = messages.findIndex((item) => item.id === id);

            messages.splice(index, 1);

            const courses = cloneDeep(messages);

            this._contatos.next(courses);

            return isDelete;
          })
        )
      )
    );
  }

  attDisparo() {
    return this.contatos$
      .pipe(
        take(1),
        switchMap((contatos: any) => {
          contatos.forEach((message, index) => {
            if (!contatos[index]?.disparo && contatos[index]?.pessoa?.celular) {
              contatos[index].disparo = true;
            }
          });

          this._contatos.next(contatos);

          return '';
        })
      )
      .subscribe(
        () => {},
        () => {}
      );
  }

  sendForm(body: any) {
    return this._httpClient.post<any>(`${environment.api}/campanha/presencial/send`, body).pipe(
      catchError((err) => err),
      tap((res: any) => res)
    );
  }

  sendDesafioResposta(body: any) {
    console.log('🚀  body:', body);
    return this._httpClient.post<any>(`${environment.api}/desafioFaegJovem/saveResposta`, body).pipe(
      catchError((err) => err),
      tap((res: any) => res)
    );
  }

  getAnalise(campanhaId: number): Observable<GetAnalise[]> {
    return this._httpClient.get<GetAnalise[]>(`${environment.api}/campanha/analiseRespostas/${campanhaId}`);
  }
}
