import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  Observable,
  of,
  switchMap,
  throwError,
  BehaviorSubject,
  tap,
  take,
} from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectSindicatoService } from 'app/shared/components/select-sindicato/select-sindicato.service';

const SERVICE = environment.api;

@Injectable()
export class AuthService {
  public _fjNotas: BehaviorSubject<string> = new BehaviorSubject('');
  private _authenticated: boolean = false;
  private _usuarioLogado: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _router: Router,
    public snackBar: MatSnackBar,
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _sindicatos: SelectSindicatoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  get userValue$(): Observable<any> {
    return this._usuarioLogado.asObservable();
  }

  get userValue() {
    return this._usuarioLogado.value;
  }

  set userValue(value: any) {
    this._usuarioLogado.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/resetPassword/sendMessage`, email)
      .pipe(
        catchError((error) => {
          return of();
        }),
        tap((contacts) => {
          console.log(contacts);
        })
      );
    // return this._httpClient.post('api/auth/forgot-password', email);
  }

  isFjNotas() {
    const pass = this._fjNotas.getValue();

    if (pass === 'notasfjavaliar2020') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/reset-password/resetPass/${id}`, password)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post(`${environment.api}/login/logar`, credentials)
      .pipe(
        switchMap((response: any) => {
          if (response.error != null) {
            localStorage.clear();
            console.log(response.error);
            if (response.error == 'unauthorized') {
              // return of ('Usuário ou Senha incorreta.');
              return throwError('Usuário ou Senha incorreta.');
            } else {
              if (response.error.indexOf('cadastrado') != -1)
                this._router.navigate(['/register']);
              return throwError(response.error);
            }
          } else {
            if (response.access_token) {
              localStorage.setItem('access_token', response.access_token);
              localStorage.setItem('nome', response.extra.nome);
              localStorage.setItem('sobrenome', response.extra.sobrenome);
              this._router.navigate(['/']);
            }
          }

          // Store the access token in the local storage
          this.accessToken = response.access_token;

          this.setUserValue().pipe(take(1)).subscribe({});
          this._sindicatos.getSindicatos().pipe(take(1)).subscribe({});

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.extra;

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  setUserValue(): Observable<any> {
    if (!this.accessToken) {
      return of(undefined);
    }

    return this._httpClient
      .get(`${environment.api}/usuario/getUsuarioLogado`)
      .pipe(
        catchError((error) => {
          console.log('🚀  error:', error);
          return of(this._usuarioLogado.next([]));
        }),
        tap((response: any) => {
          this._usuarioLogado.next(response);

          return response;
        })
      );
  }

  alterarSenha(alterarSenhaDto: any): Observable<any> {
    return this._httpClient.post(
      `${SERVICE}/integracao/changePassword`,
      alterarSenhaDto
    );
  }

  // =============================== FUNCOES SEM REQUISICAO HTTP =======================================

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post('api/auth/refresh-access-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  signOut(): Observable<any> {
    // Clear local storage
    localStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (!this.accessToken) {
      return of(false);
    }

    return of(true);
  }

  //  ================================== FUNCOES DE TOAST =======================================================

  openSuccess(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 7000,
      panelClass: 'snack-bar-success',
    });
  }

  openAlert(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 7000,
      panelClass: 'snack-bar-alert',
    });
  }

  openError(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 7000,
      panelClass: 'snack-bar-error',
    });
  }
}
