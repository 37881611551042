import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GerenciadorService } from 'app/modules/crm/gestao-campanha/campanha/gerenciador/gerenciador.service';
import { DetailsComponent } from 'app/modules/crm/gestao-campanha/campanha/gerenciador/list/modals/details/details.component';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
})
export class FormsComponent implements OnInit {
  constructor(private _dialog: MatDialog, private _location: Location, private _service: GerenciadorService) {}

  ngOnInit(): void {
    this._service.campanha$.subscribe((campanha: any) => {
      if (campanha) {
        this.openDetails(campanha);
      }
    });
  }

  openDetails(progresso: any): void {
    progresso.campanha.tipo = 'Whatsapp';

    progresso.formweb = true;

    const modal = this._dialog.open(DetailsComponent, {
      width: 'auto',
      minWidth: '100vw',
      autoFocus: false,
      height: '100vh',
      data: progresso,
    });

    modal.afterClosed().subscribe(() => {
      console.log('fechar');
      this._location.back();
    });
  }
}
