import { MunicipioMicroRegiao } from 'app/model/municipio-micro-regiao';
import { Dependente } from './dependente';
import { Etiqueta } from './etiqueta';
import { Municipio } from './municipio';
import moment from 'moment';

export class Pessoa {
  public id: number;
  public uuid: string;
  public nome: string;
  public dataNascimento;
  public estadoCivil;
  public municipioNascimento: { id: null; descricao: ''; uf: null };
  public nacionalidade: string;
  public profissao: string;
  public nomePai: string;
  public nomeMae: string;
  public nomeConjuge: string;
  public identidade: string;
  public orgaoExpedidor: string;
  public cpf: string;
  public tituloEleitor: string;
  public endereco: string;
  public bairro: string;
  public municipio: Municipio;
  public cep: string;
  public telefone: string;
  public celular: string;
  public whatsapp: boolean;
  public email: string;
  public dependentes: Dependente[];
  public etiquetas: Etiqueta[];
  public apelido: string;
  public alteradoPor: string;
  public inscEstadual: string;
  public origem: string;
  public sexo: string;
  public escolaridade: any;
  public falecido: boolean;
  public protegidoLGPD: boolean;
  public municipioMicroRegiao: MunicipioMicroRegiao;
  public racaCor: string;
  public rendaFamiliar: string;
  public deficiencia: string;
  public nomeSocial: string;
  public naturalidade: string;
  public uf: string;
  public vinculoMeioRural: string;

  constructor(pessoa?: Pessoa) {
    if (pessoa) {
      this.id = pessoa.id;
      this.uuid = pessoa.uuid;
      this.nome = pessoa.nome;
      if (pessoa.dataNascimento) {
        this.dataNascimento = moment(pessoa.dataNascimento).toDate();
      }
      this.estadoCivil = pessoa.estadoCivil;
      this.municipioNascimento = pessoa.municipioNascimento;
      this.nacionalidade = pessoa.nacionalidade;
      this.profissao = pessoa.profissao;
      this.nomePai = pessoa.nomePai;
      this.nomeMae = pessoa.nomeMae;
      this.nomeConjuge = pessoa.nomeConjuge;
      this.identidade = pessoa.identidade;
      this.orgaoExpedidor = pessoa.orgaoExpedidor;
      this.cpf = pessoa.cpf;
      this.tituloEleitor = pessoa.tituloEleitor;
      this.endereco = pessoa.endereco;
      this.bairro = pessoa.bairro;
      this.municipio = pessoa.municipio;
      this.cep = pessoa.cep;
      this.telefone = pessoa.telefone;
      this.celular = pessoa.celular;
      this.etiquetas = pessoa.etiquetas ? [...pessoa.etiquetas] : [];
      this.whatsapp = pessoa.whatsapp;
      this.email = pessoa.email;
      this.dependentes = pessoa.dependentes ? [...pessoa.dependentes] : [];

      this.dependentes.forEach((d) => {
        if (d.dataNascimento) {
          d.dataNascimento = new Date(d.dataNascimento);
          d.dataNascimento.setDate(d.dataNascimento.getUTCDate());
        }
      });
      this.apelido = pessoa.apelido;
      this.alteradoPor = pessoa.alteradoPor;
      this.inscEstadual = pessoa.inscEstadual;
      this.origem = pessoa.origem;
      this.sexo = pessoa.sexo;
      this.escolaridade = pessoa.escolaridade;
      this.falecido = pessoa.falecido;
      this.protegidoLGPD = pessoa.protegidoLGPD;
      this.municipioMicroRegiao = pessoa.municipioMicroRegiao;
      this.racaCor = pessoa.racaCor;
      this.rendaFamiliar = pessoa.rendaFamiliar;
      this.deficiencia = pessoa.deficiencia;
      this.nomeSocial = pessoa.nomeSocial;
      this.naturalidade = pessoa.naturalidade;
      this.uf = pessoa.uf;
      this.vinculoMeioRural = pessoa.vinculoMeioRural;
    } else {
      this.id = null;
      this.uuid = null;
      this.nome = null;
      this.dataNascimento = null;
      this.estadoCivil = null;
      this.municipioNascimento = null;
      this.nacionalidade = null;
      this.profissao = null;
      this.nomePai = null;
      this.nomeMae = null;
      this.nomeConjuge = null;
      this.identidade = null;
      this.orgaoExpedidor = null;
      this.cpf = null;
      this.tituloEleitor = null;
      this.endereco = null;
      this.bairro = null;
      this.municipio = null;
      this.cep = null;
      this.telefone = null;
      this.celular = null;
      this.whatsapp = false;
      this.email = null;
      this.dependentes = [];
      this.etiquetas = [];
      this.apelido = null;
      this.alteradoPor = null;
      this.inscEstadual = null;
      this.origem = null;
      this.sexo = null;
      this.escolaridade = null;
      this.falecido = null;
      this.protegidoLGPD = null;
      this.municipioMicroRegiao = null;
      this.racaCor = 'NAO_DECLARADA';
      this.rendaFamiliar = 'NAO_DECLARADA';
      this.deficiencia = 'NAO_DECLARADA';
      this.nomeSocial = null;
      this.naturalidade = null;
      this.uf = null;
      this.vinculoMeioRural = null;
    }
  }
}
