<div class="flex flex-col w-full">
    <h2 class="text-3xl font-semibold tracking-tight leading-8">Filtros</h2>
    <div class="flex flex-col mt-4">
        <div class="flex flex-row items-center w-full">
            <autocomplete-select class="w-full" [title]="'Campanha'" [options]="campanhas" [(ngModel)]="filtro.campanhaId"></autocomplete-select>

            <button mat-icon-button [color]="'warn'" (click)="limparFiltro('campanhaId')" [matTooltip]="'Limpar campanha'">
              <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>
          </div>
        <div class="flex flex-row items-center w-full">
            <autocomplete-select class="w-full" [title]="'Número'" [options]="numeros" [(ngModel)]="filtro.numero"></autocomplete-select>

            <button mat-icon-button [color]="'warn'" (click)="limparFiltro('numero')" [matTooltip]="'Limpar número'">
              <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>
          </div>
    </div>
    <div class="grid grid-cols-2 gap-4 w-full">
        <button class="w-full" mat-flat-button [color]="'warn'" (click)="fechar()">
            Fechar
        </button>
        <button class="w-full" mat-flat-button [color]="'primary'" (click)="filtrar()">
            Filtrar
        </button>
    </div>
</div>
