import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap, of, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AceitarTermosService {
    private _termo: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for termo
     */
    get termo$(): Observable<any> {
        return this._termo.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get termo
     */
    getTermo(): Observable<any> {
        return this._httpClient.get(`${environment.api}/enum/br.org.sistemafaeg.infosindical.model.enums.EnumTipoSessao`).pipe(
            catchError((error) => {
                return of(this._termo.next([]));
            }),
            tap((response: any) => {
                this._termo.next(response);
            })
        );
    }

    aceitarTermos(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.api}/usuario/aceitarTermos`, {
                date: new Date()
            })
                .subscribe(
                    res => resolve(res),
                    err => reject(err),
                );
        });
    }

    getTermoAceite(): Observable<any> {
        return this._httpClient.get(`${environment.api}/usuario/termoAceite`).pipe(
            catchError((error) => {
                return of(error)
            }),
            tap((response: any) => {
                return of(response)
            })
        );
    }
}
